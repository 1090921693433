import React from "react";
import Card from "../Card/Card";
import classes from "./Catalogue.module.css";
import { Col, Container, Row } from "reactstrap";

import logoGamme from "../../assets/images/logo-assurance-gamme.png";
import GlobalContext from "../../context/GlobalContext";

const Catalogue = () => {
  const { logo, data } = React.useContext(GlobalContext);

  const id = localStorage.getItem("api_key");

  return (
    <Container fluid className={classes.container}>
      <Row className={classes.imageContainer}>
        <Col>
          <img className={classes.logo} src={logo} alt="logo" />
        </Col>
      </Row>
      <div className={classes.box}>
        <Row>
          <Col>
            <h2 className={classes.gammeChoix}>
              Choisissez la gamme qui vous convient
            </h2>
          </Col>
        </Row>
        <Row style={{ width: "100%", justifyContent: "center" }}>
          {data.map((item, index) => (
            <Col lg="3" xs="12" style={{ margin: "0 10px" }} key={index}>
              <Card
                logo={item.logo ? item.logo : logoGamme}
                titre={
                  item.nom === "PJ vie priv\u00e9 Simple"
                    ? "Protection Juridique Vie Privée"
                    : item.nom
                }
                url={item.url_tarification + `?partnership_id=${id}`}
              />
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
};

export default Catalogue;
